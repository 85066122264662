import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import LogoMPO from "assets/images/logo_horizontal.png";
import LogoGovBR from "assets/govbr.svg";
import LogoITI from "assets/logo-iti.png";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { api } from "../../lib/axios";
import "dayjs/locale/pt-br";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("pt-br");

// const CNAES_AVALIABLES =  process.env.REACT_APP_CNAES_AVALIABLES?.split(",") ?? [];
// const CNPJWS_TOKEN = process.env.REACT_APP_CNPJWS_TOKEN ?? null;

const CNAES_AVALIABLES = ["47717", "46443", "62023"];
const CNPJWS_TOKEN = null;

/* const EXAMPLE_DATA = {
      id: "123456",
      validationCode: "1234567890",
      attachmentId: "1234567890",
      fileUrl:
        "https://www.google.com.br/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png",
      professional: {
        id: "9",
        name: "Profissional Teste",
        document: "CRO-MG 99999",
        specialty: "Psiquiatria",
      },
      user: {
        id: "1",
        name: "Paciente Teste",
        document: "04229895456",
        birthDate: "1979-11-23",
      },
      status: "PENDING",
      cnpjPharmacy: "",
      registrationDate: "",
      createdAt: "2025-01-22T12:00:00.000Z",
    }; */

function DispenserPage() {
  const { id } = useParams();
  const isDesktop = useMediaQuery("(min-width:1200px)");

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: "Carregando dados da receita médica...",
    severity: "info",
  });

  const [notifyMessage, setNotifyMessage] = useState({
    message: "",
    severity: "",
  });

  const [cnpjPharmacy, setCnpjPharmacy] = useState("");
  const [validationCode, setValidationCode] = useState("");
  const [prescriptionInfos, setPrescriptionInfos] = useState(null);
  const [prescriptionIsValid, setPrescriptionIsValid] = useState(false);
  const [pharmacyInfos, setPharmacyInfos] = useState(null);

  const normalizeCNPJ = (cnpjInput) => {
    const cnpjNumbers = cnpjInput.replace(/\D/g, "").substring(0, 14);
    return (
      cnpjNumbers
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2") || ""
    );
  };

  const normalizeCPF = (cpfInput) =>
    cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1") || "";

  const isValidCNPJ = (numbersCNPJ) => {
    if (numbersCNPJ.length !== 14) {
      return false;
    }
    const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let n = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 12; i++) {
      n += parseInt(numbersCNPJ[i], 10) * b[i + 1];
    }
    const checkDigit1 = n % 11 < 2 ? 0 : 11 - (n % 11);
    if (parseInt(numbersCNPJ[12], 10) !== checkDigit1) {
      return false;
    }
    n = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 13; i++) {
      n += parseInt(numbersCNPJ[i], 10) * b[i];
    }
    const checkDigit2 = n % 11 < 2 ? 0 : 11 - (n % 11);
    return parseInt(numbersCNPJ[13], 10) === checkDigit2;
  };

  const getPrescriptionInfos = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/prescription/${id}`);

      // const data = EXAMPLE_DATA;
      setPrescriptionInfos(data);
      setPrescriptionIsValid(true);
      setAlertMessage({
        message: "",
        severity: "",
      });
    } catch (err) {
      setAlertMessage({
        message: "Erro ao buscar receita médica",
        severity: "error",
      });
      setPrescriptionIsValid(false);
    } finally {
      setLoading(false);
    }
  };

  const prescriptionDispenser = async () => {
    if (!prescriptionInfos) {
      return;
    }
    if (!pharmacyInfos) {
      setNotifyMessage({
        message:
          "Estabelecimento não definido. Não é possível dispensar a receita.",
        severity: "error",
      });
      return;
    }
    const cnaesExtracted =
      pharmacyInfos.estabelecimento.atividades_secundarias.map(
        (item) => item.id,
      );
    cnaesExtracted.push(pharmacyInfos.estabelecimento.atividade_principal.id);
    const cnaesAvaliableFound = cnaesExtracted.some((item) =>
      CNAES_AVALIABLES.some((prefix) => item.startsWith(prefix)),
    );
    if (!cnaesAvaliableFound) {
      setNotifyMessage({
        message:
          "Seu estabelecimento não possui autorização para venda de medicamentos.",
        severity: "error",
      });
      return;
    }
    setLoading(true);
    try {
      const response = await api.put(`/prescription/${id}/dispense`, {
        cnpjPharmacy: cnpjPharmacy.replace(/\D/g, ""),
        validationCode,
      });
      if (response.status >= 400) {
        setAlertMessage({
          message:
            response?.data?.message || "Erro na dispensação da receita médica",
          severity: "error",
        });
        return;
      }
      prescriptionInfos.status = "DISPENSED";
      prescriptionInfos.registrationDate = dayjs().toISOString();
      setAlertMessage({
        message: "Receita dispensada com sucesso!",
        severity: "success",
      });
    } catch (err) {
      setNotifyMessage({
        message:
          err.response?.data?.message ||
          "Erro na dispensação da receita médica",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentIputed = async () => {
    if (!isValidCNPJ(cnpjPharmacy.replace(/\D/g, ""))) {
      setNotifyMessage({
        message: "CNPJ Inválido",
        severity: "error",
      });
      return;
    }
    setNotifyMessage({
      message:
        "Buscando dados do estabelecimento na base nacional de empresas...",
      severity: "info",
    });
    setLoading(true);
    try {
      const urlRequest = CNPJWS_TOKEN
        ? `https://comercial.cnpj.ws/cnpj/${cnpjPharmacy.replace(/\D/g, "")}?token=${CNPJWS_TOKEN}`
        : `https://publica.cnpj.ws/cnpj/${cnpjPharmacy.replace(/\D/g, "")}`;
      const response = await fetch(urlRequest, { mode: "cors" });
      if (response.status === 429) {
        setNotifyMessage({
          message:
            "Servidor ocupado. Aguarde pelo menos 60 segundos e tente novamente.",
          severity: "error",
        });
        return;
      }
      if (response.status > 400) {
        setNotifyMessage({
          message: "Erro na busca dos dados para o CNPJ informado.",
          severity: "error",
        });
        return;
      }
      const data = await response.json();
      setNotifyMessage({
        message: "Estabelecimento localizado.",
        severity: "success",
      });
      setPharmacyInfos(data);
    } catch (err) {
      setNotifyMessage({
        message: "Erro na busca dos dados para o CNPJ informado.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getInfos() {
      await getPrescriptionInfos();
    }
    getInfos();
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          width: "100%",
          padding: 1,
          marginBottom: "50px",
        }}
      >
        <Backdrop
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(10, 10, 10, 0.5)",
            zIndex: 20000,
          }}
          open={loading}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress color="primary" size={60} />

            <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
              <Typography
                variant="body1"
                style={{ color: "white", padding: 20 }}
              >
                Carregando...
              </Typography>
            </Box>
          </Box>
        </Backdrop>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            width: "100%",
            minHeight: "100vh",
            paddingX: { xs: 2, lg: 30 },
          }}
        >
          <Box component="img" src={LogoMPO} alt="Logo MPO" width="300px" />
          <Typography variant="h5" fontWeight={500}>
            Dispensador de Receitas
          </Typography>

          {alertMessage.message !== "" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                width: "100%",
                padding: 2,
                marginY: 1,
                borderRadius: 2,
                backgroundColor:
                  alertMessage.severity === "error" ? "#FF5722" : "#11B3AE",
                color: "white",
              }}
            >
              <Typography variant="body1">{alertMessage.message}</Typography>
            </Box>
          )}
          {prescriptionInfos && prescriptionIsValid && (
            <>
              <Typography variant="body2">
                Data emissão:{" "}
                {dayjs(prescriptionInfos.createdAt)
                  .tz("UTC")
                  .format("DD/MM/YYYY")}{" "}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    padding: "10px",
                    borderRadius: "10px",
                    border: "1px solid #11B3AE",
                    width: { xs: "100%", lg: "49%" },
                    marginY: { xs: 0.3, lg: 1 },
                  }}
                >
                  <Typography variant="body1" fontWeight={500}>
                    Dados do Paciente
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                    Nome: {prescriptionInfos.user.name}
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                    Documento:{" "}
                    {prescriptionInfos?.user?.document
                      ? normalizeCPF(prescriptionInfos.user.document)
                      : ""}
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                    Data Nascimento:{" "}
                    {prescriptionInfos?.user?.birthDate
                      ? dayjs(prescriptionInfos.user.birthDate)
                          .tz("UTC")
                          .format("DD/MM/YYYY")
                      : ""}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: "10px",
                    borderRadius: "10px",
                    border: "1px solid #11B3AE",
                    width: { xs: "100%", lg: "49%" },
                    marginY: { xs: 0.3, lg: 1 },
                  }}
                >
                  <Typography variant="body1" fontWeight={500}>
                    Dados do Profissional
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                    Nome: {prescriptionInfos.professional.name}
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                    Documento: {prescriptionInfos.professional.document}
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                    Especialidade: {prescriptionInfos.professional.specialty}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: 1,
                  marginY: { xs: 0.3, lg: 1 },
                  borderRadius: "10px",
                  border: "1px solid #11B3AE",
                }}
              >
                {isDesktop && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      alignItems: "center",
                      backgroundColor: "#e1e1e1",
                      padding: 1,
                      height: "150px",
                      width: "150px",
                      borderRadius: "10px",
                    }}
                  >
                    <QRCode
                      value={`${window.location.origin}/download/${prescriptionInfos.attachmentId}`}
                      size={200}
                    />
                    <Button
                      variant="outlined"
                      size="small"
                      href={`${window.location.origin}/download/${prescriptionInfos.attachmentId}`}
                      target="_blank"
                      sx={{
                        background:
                          "linear-gradient(to bottom right, #11284F, #11B3AE)",
                        marginTop: 1,
                        width: "100%",
                        color: "white",
                      }}
                    >
                      Download
                    </Button>
                  </Box>
                )}
                {!isDesktop && (
                  <Button
                    variant="outlined"
                    href={`${window.location.origin}/download/${prescriptionInfos.attachmentId}`}
                    target="_blank"
                    sx={{
                      background:
                        "linear-gradient(to bottom right, #11284F, #11B3AE)",
                      width: "100%",
                      color: "white",
                    }}
                  >
                    Download Receita em PDF
                  </Button>
                )}
                <Box sx={{ padding: 1, flex: 1 }}>
                  <Typography variant="body1" fontWeight={500}>
                    Arquivo da receita médica
                  </Typography>
                  <Typography
                    variant="body2"
                    textAlign="justify"
                    sx={{ lineHeight: 1.2 }}
                  >
                    Para validar a assinatura do documento faça o download do
                    arquivo PDF e envie para o site de validação do governo
                    federal{" "}
                    <a
                      href="https://validar.iti.gov.br"
                      target="_blank"
                      style={{ color: "#b60000" }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.color = "#11B3AE";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.color = "#b60000";
                      }}
                      rel="noreferrer"
                    >
                      validar.iti.gov.br
                    </a>{" "}
                    utilizando o recurso ESCOLHER ARQUIVO exibido como um botão
                    na página inicial.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "row", lg: "column" },
                    justifyContent: "space-around",
                    alignItems: "center",
                    backgroundColor: "#071d41",
                    padding: { xs: 1, lg: 2 },
                    height: { xs: "60px", lg: "150px" },
                    width: { xs: "100%", lg: "150px" },
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    component="img"
                    src={LogoGovBR}
                    alt="gov.br"
                    style={{
                      width: isDesktop ? "100%" : "auto",
                      height: "100%",
                    }}
                  />
                  <Box
                    component="img"
                    src={LogoITI}
                    alt="validar.iti.gov.br"
                    style={{
                      width: isDesktop ? "100%" : "auto",
                      height: "100%",
                    }}
                  />
                </Box>
              </Box>
              {prescriptionInfos.status === "PENDING" && (
                <>
                  <Typography variant="body2" fontWeight={500}>
                    Preencha para dispensar a receita:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        lg: "row",
                      },
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginY: { xs: 0.3, lg: 1 },
                      gap: 1,
                    }}
                  >
                    <TextField
                      id="cnpjPharmacy"
                      label="CNPJ do estabelecimento"
                      fullWidth
                      value={cnpjPharmacy}
                      onChange={(e) =>
                        setCnpjPharmacy(normalizeCNPJ(e.target.value))
                      }
                      onFocus={() => setPharmacyInfos(null)}
                      onBlur={handleDocumentIputed}
                      size="small"
                      sx={{ marginY: { xs: 0.3, lg: 1 } }}
                      autoFocus
                    />
                    <TextField
                      id="validationCode"
                      label="Chave de validação da receita médica"
                      fullWidth
                      value={validationCode}
                      onChange={(e) => setValidationCode(e.target.value)}
                      size="small"
                      sx={{ marginY: { xs: 0.3, lg: 1 } }}
                    />
                  </Box>
                  {pharmacyInfos && (
                    <Box sx={{ padding: "2px" }}>
                      <Typography variant="body2">
                        Razão Social do Estabelecimento:{" "}
                        {pharmacyInfos?.razao_social ??
                          "Razão social não encontrada."}
                      </Typography>
                    </Box>
                  )}
                  <Button
                    type="submit"
                    variant="outlined"
                    onClick={prescriptionDispenser}
                    sx={{
                      width: "100%",
                      marginTop: { xs: 0, lg: 2 },
                      background:
                        "linear-gradient(to bottom right, #11284F, #11B3AE)",
                      color: "white",
                    }}
                  >
                    Dispensar receita médica
                  </Button>
                </>
              )}
              {prescriptionInfos.status === "DISPENSED" && (
                <Typography variant="h6" fontWeight={500}>
                  Receita foi dispensada em{" "}
                  {dayjs(prescriptionInfos.registrationDate).format(
                    "DD/MM/YYYY [às] HH:mm",
                  )}
                  .
                </Typography>
              )}
              {prescriptionInfos.status === "CANCELED" && (
                <Typography variant="h5" fontWeight={500}>
                  A dispensação dessa receita foi cancelada.
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
      <Snackbar
        open={notifyMessage.message !== ""}
        onClose={() => setNotifyMessage({ message: "", type: "" })}
        message={notifyMessage.message}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
}

export default DispenserPage;
