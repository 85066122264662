/* eslint-disable no-console */
import React, { useState, useContext, useEffect } from "react";
import { Context } from "Context/AuthContext";

import { Link, useHistory } from "react-router-dom";

import { LoginSocialApple, LoginSocialGoogle } from "reactjs-social-login";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";

import routes from "routes";
import Menu from "components/Menu";
import LogoMPO from "assets/images/logo_horizontal.png";

// @mui material components
import {
  Card,
  CircularProgress,
  Switch,
  Backdrop,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
} from "@mui/material";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import { Visibility, VisibilityOff } from "@mui/icons-material";

function Login() {
  const REDIRECT_URI = "https://meupsiquiatraonline.com.br/login";

  const history = useHistory();
  const { handleLogin } = useContext(Context);

  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  const [rememberMe, setRememberMe] = useState(true);
  const [mailOrCPF, setMailOrCPF] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleShowPassword = () => setShowPassword(!showPassword);

  const socialLoginGoogleState =
    process.env.REACT_APP_SOCIAL_LOGIN_GOOGLE === "false";
  const socialLoginAppleState =
    process.env.REACT_APP_SOCIAL_LOGIN_APPLE === "false";

  const redirectAuthenticatedUser = (user) => {
    if (user?.type) {
      if (user.type === "user") {
        history.push("/user-appointments");
        return;
      }
      if (user.type === "professional") {
        history.push("/professional-appointments");
        return;
      }
      if (user.type === "admin") {
        history.push("/administrator-area");
        return;
      }
      setAlertComponent({
        message: "Você não possui permissão para utilizar o APP",
        type: "error",
      });
    }
  };

  const handleLoginSubmit = async () => {
    setLoading(true);
    try {
      const data = {
        mailOrCPF,
        password,
        rememberMe,
      };
      // Executar o login
      await handleLogin(data);

      const authenticatedUser =
        JSON.parse(localStorage.getItem("user")) ?? false;

      if (authenticatedUser) {
        redirectAuthenticatedUser(authenticatedUser);
      } else {
        setAlertComponent({
          message:
            "Erro na validação dos dados. Se seu cadastro foi feito no app, defina uma senha na área do usuário.",
          type: "error",
        });
      }
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Erro no login.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const validateSocialAccess = async (userInfo, token, type) => {
    setLoading(true);
    let name;
    if (type === "apple") {
      if (userInfo.fullName.givenName) {
        name = userInfo.fullName.givenName;
      }
      if (userInfo.fullName.middleName) {
        name = `${name} ${userInfo.fullName.middleName}`;
      }
      if (userInfo.fullName.familyName) {
        name = `${name} ${userInfo.fullName.familyName}`;
      }
    } else {
      name = userInfo.name;
    }

    const data = {
      email: userInfo?.email,
      name,
      type,
      token,
    };

    try {
      await handleLogin(data);

      const authenticatedUser =
        JSON.parse(localStorage.getItem("user")) ?? false;

      if (!authenticatedUser) {
        setAlertComponent({
          message: "Erro na validação dos dados de acesso.",
          type: "error",
        });
        return;
      }

      redirectAuthenticatedUser(authenticatedUser);
    } catch (err) {
      setAlertComponent({
        message:
          err.response?.data?.message ||
          "Erro na validação dos dados de acesso.",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("user")) {
      if (localStorage.getItem("user").type === "professional") {
        window.location.replace("/professional-area");
      }
      window.location.replace("/user-area");
    }
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes.public} sticky />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          paddingTop: "180px",
        }}
      >
        <Card sx={{ minWidth: "50%", maxWidth: "98%" }}>
          <MKBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-2}
            py={1}
            mb={1}
            textAlign="center"
          >
            <MKTypography variant="body2" color="white" my={1}>
              Área de Login
            </MKTypography>
          </MKBox>
          <MKBox pt={4} pb={3} px={3}>
            {alertComponent.message !== "" && (
              <Grid item xs={12}>
                <MKAlert color={alertComponent.type}>
                  {alertComponent.message}
                </MKAlert>
              </Grid>
            )}
            <MKBox>
              <MKBox mb={2}>
                <MKInput
                  type="text"
                  label="E-mail ou CPF"
                  name="mailOrCPF"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={mailOrCPF}
                  onChange={(e) => setMailOrCPF(e.target.value)}
                />
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  type={showPassword ? "text" : "password"}
                  label="Senha"
                  name="password"
                  fullWidth
                  placeholder="************"
                  InputLabelProps={{ shrink: true }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MKBox>
              <MKBox display="flex" alignItems="center">
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  &nbsp;&nbsp;Lembre-se de mim!
                </MKTypography>
              </MKBox>
              <MKBox mt={4} mb={1}>
                <MKButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleLoginSubmit}
                >
                  Fazer Login
                </MKButton>
              </MKBox>
              {socialLoginAppleState && (
                <Box sx={{ paddingY: "5px" }}>
                  <LoginSocialApple
                    client_id={process.env.REACT_APP_APPLE_KEY || ""}
                    scope="name email"
                    redirect_uri={REDIRECT_URI}
                    // onLoginStart={onLoginStart}
                    onResolve={({ provider, data }) => {
                      console.log("provider: ", provider);
                      console.log("AppleData: ", data);
                    }}
                    onReject={() => {
                      setAlertComponent({
                        message: "Erro com login social da Apple.",
                        type: "error",
                      });
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        minHeight: "48px",
                        maxHeight: "48px",
                        borderRadius: "6px",
                        width: "100%",
                        backgroundColor: "#ffffff",
                        activeOpacity: 0.7,
                        border: "1px solid #eee",
                        boxShadow:
                          "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)",
                        cursor: "pointer",
                      }}
                    >
                      <AppleIcon />
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "18px",
                          marginLeft: "10px",
                        }}
                      >
                        Login com Apple
                      </Typography>
                    </Box>
                  </LoginSocialApple>
                </Box>
              )}
              {socialLoginGoogleState && (
                <Box sx={{ paddingY: "5px" }}>
                  <LoginSocialGoogle
                    client_id={process.env.REACT_APP_GOOGLE_KEY || ""}
                    // onLoginStart={onLoginStart}
                    redirect_uri={REDIRECT_URI}
                    scope="openid profile email"
                    discoveryDocs="claims_supported"
                    access_type="offline"
                    onResolve={async ({ provider, data }) => {
                      await validateSocialAccess(data, data.sub, provider);
                    }}
                    onReject={() => {
                      setAlertComponent({
                        message: "Erro com login social do Google.",
                        type: "error",
                      });
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        minHeight: "48px",
                        maxHeight: "48px",
                        borderRadius: "6px",
                        width: "100%",
                        backgroundColor: "#ffffff",
                        activeOpacity: 0.7,
                        border: "1px solid #eee",
                        boxShadow:
                          "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)",
                        cursor: "pointer",
                      }}
                    >
                      <GoogleIcon size={48} color="#fff" />
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "18px",
                          marginLeft: "10px",
                        }}
                      >
                        Login com Google
                      </Typography>
                    </Box>
                  </LoginSocialGoogle>
                </Box>
              )}
              <MKBox mb={0} textAlign="center">
                <MKTypography variant="button" color="text">
                  Não tem uma conta?{" "}
                  <MKTypography
                    component={Link}
                    to="/register"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Registre-se
                  </MKTypography>
                </MKTypography>
              </MKBox>
              <MKBox mt={0} mb={1} textAlign="center">
                <MKTypography variant="button" color="text">
                  Esqueceu sua senha?{" "}
                  <MKTypography
                    component={Link}
                    to="/forgot-password"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Recuperar senha!
                  </MKTypography>
                </MKTypography>
              </MKBox>
            </MKBox>
          </MKBox>
        </Card>
      </Box>
    </>
  );
}

export default Login;
