import React, { useEffect, useState } from "react";
import routes from "routes";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";

import Menu from "components/Menu";

import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import LogoMPO from "assets/images/logo_horizontal.png";

import { api } from "../../../lib/axios";
import MKBox from "../../../components/MKBox";
import MKAlert from "../../../components/MKAlert";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("pt-br");

function Prescriptions() {
  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);

  const [prescriptions, setPrescriptions] = useState([]);

  const [prescriptionModal, setPrescriptionModal] = useState(false);
  const [prescriptionSelected, setPrescriptionSelected] = useState(null);

  const [cnpjPharmacy, setCnpjPharmacy] = useState("");
  const [validationCode, setValidationCode] = useState("");

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const PrescriptionStatusEnum = {
    PENDING: "Pendente",
    DISPENSED: "Dispensada",
    CANCELED: "Cancelada",
  };

  const normalizeCPF = (cpfInput) =>
    cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1") || "";

  const normalizeCNPJ = (cnpjInput) => {
    const cnpjNumbers = cnpjInput.replace(/\D/g, "").substring(0, 14);
    return (
      cnpjNumbers
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2") || ""
    );
  };

  const isValidCNPJ = (numbersCNPJ) => {
    if (numbersCNPJ.length !== 14) {
      return false;
    }
    const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let n = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 12; i++) {
      n += parseInt(numbersCNPJ[i], 10) * b[i + 1];
    }
    const checkDigit1 = n % 11 < 2 ? 0 : 11 - (n % 11);
    if (parseInt(numbersCNPJ[12], 10) !== checkDigit1) {
      return false;
    }
    n = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 13; i++) {
      n += parseInt(numbersCNPJ[i], 10) * b[i];
    }
    const checkDigit2 = n % 11 < 2 ? 0 : 11 - (n % 11);
    return parseInt(numbersCNPJ[13], 10) === checkDigit2;
  };

  const closePrescriptionModal = () => {
    setPrescriptionModal(false);
    setPrescriptionSelected(null);
  };

  const getPrescriptions = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/prescriptions");

      const prescriptionsFound = data?.prescriptions || [];
      const prescriptionsList = prescriptionsFound.map((item) => ({
        ...item,
        patientName: item.user.name,
        patientDocument: normalizeCPF(item.user.document),
        professionalName: item.professional.name,
        pharmacyDocument: item?.cnpjPharmacy
          ? normalizeCNPJ(item.cnpjPharmacy)
          : "",
      }));
      setPrescriptions(prescriptionsList);
    } catch (error) {
      setAlertComponent({
        message: "Erro ao buscar receitas médicas",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const prescriptionDispenser = async () => {
    if (!prescriptionSelected) {
      setAlertComponent({
        message: "Dados da receita não encontrado.",
        type: "error",
      });
      return;
    }
    if (!isValidCNPJ(cnpjPharmacy.replace(/\D/g, ""))) {
      setAlertComponent({
        message: "CNPJ inválido",
        type: "error",
      });
      return;
    }
    setLoading(true);
    try {
      const response = await api.post(
        `/prescriptions/${prescriptionSelected.id}`,
        {
          cnpjPharmacy,
          validationCode,
        },
      );
      if (response.status >= 400) {
        const data = await response.json();
        setAlertComponent({
          message: data?.message || "Erro na dispensação da receita médica",
          severity: "error",
        });
        return;
      }
      setAlertComponent({
        message: "Receita dispensada com sucesso!",
        type: "success",
      });
      setPrescriptionModal(false);
      setPrescriptionSelected(null);
      await getPrescriptions();
    } catch (err) {
      if (err instanceof Error) {
        setAlertComponent({
          message: err.message,
          type: "error",
        });
      } else {
        setAlertComponent({
          message: "Erro na dispensação da receita médica",
          type: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const changePrescriptionStatus = async (prescriptionInfosInput) => {
    if (!prescriptionInfosInput?.id) {
      setAlertComponent({
        message: "Nenhuma receita médica selecionada",
        type: "error",
      });
      return;
    }
    if (prescriptionInfosInput.status === "DISPENSED") {
      setAlertComponent({
        message:
          "Receita já foi dispensada. Status não pode ser mais alterado.",
        type: "error",
      });
      return;
    }
    setLoading(true);
    try {
      const response = await api.put(
        `/prescriptions/${prescriptionInfosInput.id}`,
        {
          status:
            prescriptionInfosInput.status === "PENDING"
              ? "CANCELED"
              : "PENDING",
        },
      );
      if (response.status === 401) {
        setAlertComponent({
          message: "Acesso não autorizado. Refaça o login",
          type: "error",
        });
      }
    } catch (error) {
      setAlertComponent({
        message: "Erro ao cancelar a receita médica",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const prescriptionsColumns = [
    {
      field: "patientName",
      headerName: "Paciente",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "patientDocument",
      headerName: "CPF Paciente",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "professionalName",
      headerName: "Profissional",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "createdAt",
      headerName: "Data de Emissão",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
      renderCell: (params) =>
        dayjs(params.row.createdAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "pharmacyDocument",
      headerName: "CNPJ do Estabelecimento",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "registrationDate",
      headerName: "Dispensação",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
      renderCell: (params) =>
        dayjs(params.row.registrationDate).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => PrescriptionStatusEnum[params.row.status],
    },
    {
      field: "actions",
      headerName: "Ações",
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            alignContent: "center",
            height: "100%",
          }}
        >
          <Tooltip
            title="Visualizar Receita Médica"
            placement="top-start"
            arrow
          >
            <VisibilityIcon
              onClick={() => {
                setPrescriptionSelected(params.row);
                setPrescriptionModal(true);
              }}
              sx={{ marginX: "2px", cursor: "pointer" }}
            />
          </Tooltip>
          {params.row.status !== "DISPENSED" && (
            <Tooltip title="Habilitar / Cancelar" placement="top" arrow>
              <Switch
                color={params.row.active ? "primary" : "error"}
                checked={params.row.active}
                onChange={() => changePrescriptionStatus(params.row)}
              />
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (!authenticatedUser) {
      window.location.replace("/login");
    }
    async function getInfos() {
      await getPrescriptions();
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        component="section"
        position="relative"
        py={15}
        width="100%"
        height="100vh"
      >
        <Typography sx={{ textAlign: "center" }}>Receitas Médicas</Typography>
        <Grid
          container
          alignItems="top"
          sx={{ display: "flex", alignContent: "stretch" }}
        >
          <Grid item xs={12} lg={12}>
            <MKBox
              sx={{
                height: "72vh",
                width: "100%",
                paddingX: "20px",
                textAlign: "center",
                "& .dataGrid-header": {
                  backgroundColor: "#00bbd4",
                  color: "#fff",
                  fontWeight: "bold",
                },
              }}
            >
              {alertComponent.message && (
                <Grid item xs={12}>
                  <MKAlert color={alertComponent.type}>
                    {alertComponent.message}
                  </MKAlert>
                </Grid>
              )}
              <DataGrid
                rows={prescriptions}
                columns={prescriptionsColumns}
                density="compact"
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                getCellClassName={(params) => {
                  if (params.row.status === "CANCELED") {
                    return "rowRemoved";
                  }
                  return "";
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>

      <Dialog
        open={prescriptionModal}
        onClose={closePrescriptionModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography>Detalhes da Receita Médica</Typography>
          <CloseIcon
            onClick={closePrescriptionModal}
            sx={{ cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "40px" }}>
          {prescriptionSelected && (
            <>
              <Typography variant="body2">
                Data emissão:{" "}
                {dayjs(prescriptionSelected.createdAt)
                  .tz("UTC")
                  .format("DD/MM/YYYY")}{" "}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    padding: "10px",
                    borderRadius: "10px",
                    border: "1px solid #11B3AE",
                    width: { xs: "100%", md: "49%" },
                    marginY: { xs: 0.3, md: 1 },
                  }}
                >
                  <Typography variant="body1" fontWeight={500}>
                    Dados do Paciente
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                    Nome: {prescriptionSelected.user.name}
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                    Documento:{" "}
                    {prescriptionSelected?.user?.document
                      ? normalizeCPF(prescriptionSelected.user.document)
                      : ""}
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                    Data Nascimento:{" "}
                    {prescriptionSelected?.user?.birthDate
                      ? dayjs(prescriptionSelected.user.birthDate)
                          .tz("UTC")
                          .format("DD/MM/YYYY")
                      : ""}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: "10px",
                    borderRadius: "10px",
                    border: "1px solid #11B3AE",
                    width: { xs: "100%", md: "49%" },
                    marginY: { xs: 0.3, md: 1 },
                  }}
                >
                  <Typography variant="body1" fontWeight={500}>
                    Dados do Profissional
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                    Nome: {prescriptionSelected.professional.name}
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                    Documento: {prescriptionSelected.professional.document}
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                    Especialidade: {prescriptionSelected.professional.specialty}
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="outlined"
                href={`${window.location.origin}/download/${prescriptionSelected.attachmentId}`}
                target="_blank"
                sx={{
                  background:
                    "linear-gradient(to bottom right, #11284F, #11B3AE)",
                  width: "100%",
                  color: "white",
                }}
              >
                Download Receita em PDF
              </Button>
              {prescriptionSelected.status === "PENDING" && (
                <>
                  <Typography variant="body2" fontWeight={500}>
                    Preencha para dispensar a receita:
                  </Typography>
                  <TextField
                    id="cnpjPharmacy"
                    label="CNPJ do estabelecimento"
                    fullWidth
                    value={cnpjPharmacy}
                    onChange={(e) =>
                      setCnpjPharmacy(normalizeCNPJ(e.target.value))
                    }
                    size="small"
                    sx={{ marginY: { xs: 0.3, md: 1 } }}
                    autoFocus
                  />
                  <TextField
                    id="validationCode"
                    label="Chave de validação da receita médica"
                    fullWidth
                    value={validationCode}
                    onChange={(e) => setValidationCode(e.target.value)}
                    size="small"
                    sx={{ marginY: { xs: 0.3, md: 1 } }}
                  />
                  <Button
                    type="submit"
                    variant="outlined"
                    onClick={prescriptionDispenser}
                    sx={{
                      width: "100%",
                      marginTop: { xs: 0, md: 2 },
                      background:
                        "linear-gradient(to bottom right, #11284F, #11B3AE)",
                      color: "white",
                    }}
                  >
                    Dispensar receita médica
                  </Button>
                </>
              )}
              {prescriptionSelected.status === "DISPENSED" && (
                <Typography variant="h5" fontWeight={500}>
                  Receita já foi dispensada!
                </Typography>
              )}
              {prescriptionSelected.status === "CANCELED" && (
                <Typography variant="h5" fontWeight={500}>
                  A dispensação dessa receita foi cancelada.
                </Typography>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Prescriptions;
