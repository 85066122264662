/* eslint-disable no-alert */
import React, { useContext, useEffect, useState } from "react";
import * as jose from "jose";
import { Context } from "Context/AuthContext";
import { Link } from "react-router-dom";
import routes from "routes";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CircularProgress,
  Container,
  Grid,
  Backdrop,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";

import LogoMPO from "assets/images/logo_horizontal.png";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import { api } from "../../lib/axios";

function AdministratorArea() {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { handleLogout } = useContext(Context);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);

  const [apointmentsFiltered, setApointmentsFiltered] = useState(null);

  const getAppointments = async () => {
    setLoading(true);
    try {
      const response = await api.get("/schedules", {
        params: {
          dateStart: dayjs().tz("America/Sao_Paulo").startOf("day"),
          status: ["AGENDADO", "PROCESSANDO"],
        },
      });
      setApointmentsFiltered(response.data);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const removeSchedule = async (id) => {
    setLoading(true);
    try {
      await api.delete(`/schedules/${id}`);
      setAlertComponent({
        message: "Pré-Agendamento excluído com sucesso!",
        type: "success",
      });
      await getAppointments();
    } catch (err) {
      setAlertComponent({
        message:
          err.response?.data?.message ||
          "Ocorreu um erro na exclusão do pré-agendamento!",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!authenticatedUser) {
      window.location.replace("/login");
    }

    const decodedToken = jose.decodeJwt(localStorage.getItem("token"));
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (
      authenticatedUser.type !== "admin" ||
      decodedToken.exp <= currentTimestamp
    ) {
      handleLogout();
      window.location.replace("/");
    }

    if (!apointmentsFiltered) {
      getAppointments();
    }
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox
          component="section"
          position="relative"
          py={20}
          width="100%"
          height="100vh"
        >
          <Grid
            container
            alignItems="top"
            sx={{ display: "flex", alignContent: "stretch" }}
          >
            {alertComponent.message && (
              <Grid item xs={12}>
                <MKAlert color={alertComponent.type}>
                  {alertComponent.message}
                </MKAlert>
              </Grid>
            )}
            <Grid item xs={12} lg={6} py={3} pr={{ xl: 1, lg: 0 }}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={3}
                  mb={1}
                  textAlign="center"
                >
                  <MKTypography
                    variant="h4"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    MENU DE ACESSO
                  </MKTypography>
                </MKBox>
                <MKBox mx={2} mb={1} height="auto">
                  <MKTypography variant="h5" py={0.5} textAlign="center">
                    Botões de acesso rápido
                  </MKTypography>
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/appointments"
                      fullWidth
                    >
                      Controle de Agendamentos
                    </MKButton>
                  </MKBox>
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/professionals"
                      fullWidth
                    >
                      Controle de Profissionais
                    </MKButton>
                  </MKBox>
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/coupons"
                      fullWidth
                    >
                      Controle de Cupons
                    </MKButton>
                  </MKBox>
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/users"
                      fullWidth
                    >
                      Controle de Usuários
                    </MKButton>
                  </MKBox>
                  {/* <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/prescriptions"
                      fullWidth
                    >
                      Controle de Prescrições
                    </MKButton>
                  </MKBox> */}
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/questions"
                      fullWidth
                    >
                      Perguntas & Respostas
                    </MKButton>
                  </MKBox>
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/store"
                      fullWidth
                    >
                      Loja Virtual
                    </MKButton>
                  </MKBox>
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/push"
                      fullWidth
                    >
                      Mensagens PUSH
                    </MKButton>
                  </MKBox>
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/marketing"
                      fullWidth
                    >
                      Campanhas de Marketing
                    </MKButton>
                  </MKBox>
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/schedules-sales"
                      fullWidth
                    >
                      Vendas Agendamentos
                    </MKButton>
                  </MKBox>
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/store-sales"
                      fullWidth
                    >
                      Vendas Produtos
                    </MKButton>
                  </MKBox>
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/split-accounts"
                      fullWidth
                    >
                      Contas Split
                    </MKButton>
                  </MKBox>
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/mpo-plan-users"
                      fullWidth
                    >
                      Usuários Plano MPO
                    </MKButton>
                  </MKBox>
                  <MKBox width="100%" py={0.5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      component={Link}
                      to="/administrator-area/quick-services"
                      fullWidth
                    >
                      Atendimentos Rápidos
                    </MKButton>
                  </MKBox>
                </MKBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} py={3} pl={{ xl: 1, lg: 0 }}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={3}
                  mb={1}
                  textAlign="center"
                >
                  <MKTypography
                    variant="h4"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    Próximos Atendimentos
                  </MKTypography>
                </MKBox>
                <MKBox mx={2} mb={1} height="auto">
                  {apointmentsFiltered &&
                    apointmentsFiltered.map((appointment) => (
                      <Accordion key={appointment.id}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            backgroundColor:
                              appointment.status === "PROCESSANDO"
                                ? "#F0F0F0"
                                : "white",
                          }}
                        >
                          <MKTypography variant="body2">
                            {appointment.user.name} - {appointment.status}
                          </MKTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <MKTypography variant="body2">
                            <b>ID: </b>
                            {appointment.id}
                          </MKTypography>
                          <MKTypography variant="body2">
                            <b>Solicitado em: </b>
                            {dayjs(appointment.created_at)
                              .tz("America/Sao_Paulo")
                              .format("DD/MM/YYYY HH:mm")}
                          </MKTypography>
                          <MKTypography variant="body2">
                            <b>Data e Hora: </b>
                            {dayjs
                              .tz(appointment.date_start, "America/Sao_Paulo")
                              .format("DD/MM/YYYY HH:mm")}
                          </MKTypography>
                          <MKTypography variant="body2">
                            <b>Usuário: </b>
                            {appointment.user.name}
                          </MKTypography>
                          <MKTypography variant="body2">
                            <b>Telefone: </b>
                            {`(${appointment.user.phone.substring(
                              2,
                              4,
                            )}) ${appointment.user.phone.substring(
                              4,
                              9,
                            )}-${appointment.user.phone.substring(9, 13)}`}
                          </MKTypography>
                          <MKTypography variant="body2">
                            <b>Profissional: </b>
                            {appointment.professional.name}
                          </MKTypography>
                          <MKTypography variant="body2">
                            <b>Valor: </b>
                            {`R$ ${Number(appointment.price)
                              .toFixed(2)
                              .replace(".", ",")}`}
                          </MKTypography>
                          <MKTypography variant="body2">
                            <b>Status: </b>
                            {appointment.status}
                          </MKTypography>
                          {appointment.status === "PROCESSANDO" && (
                            <MKButton
                              variant="gradient"
                              color="error"
                              fullWidth
                              onClick={() => removeSchedule(appointment.id)}
                            >
                              Excluir Pré-Agendamento
                            </MKButton>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
      </Container>
    </>
  );
}

export default AdministratorArea;
