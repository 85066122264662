/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import routes from "routes";

import { ptBR as datePtBr } from "date-fns/locale";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { ScheduleMeeting } from "react-schedule-meeting";

// @mui material components
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ArticleIcon from "@mui/icons-material/Article";

import LogoMPO from "assets/images/logo_horizontal.png";

import * as jose from "jose";
import { api } from "../../../lib/axios";

function AppointmentsAdminPanel() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);
  dayjs.locale("pt-br");

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [alertModalInfos, setAlertModalInfos] = useState({
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [nascimento, setNascimento] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [zip, setZip] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [complement, setComplement] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [professional, setProfessional] = useState("");
  const [serviceId, setServiceId] = useState("");

  const [services, setServices] = useState("");
  const [professionals, setProfessionals] = useState({});

  const [appointments, setAppointments] = useState({});
  const [appointmentSelected, setAppointmentSelected] = useState();
  const [scheduleDetails, setScheduleDetails] = useState({});
  const [openAppointmentDetailsModal, setOpenAppointmentDetailsModal] =
    useState(false);

  const [coupon, setCoupon] = useState("");
  const [timeSelected, setTimeSelected] = useState("");
  const [timeSelectedForBuy, setTimeSelectedForBuy] = useState("");
  const [availableTimeslots, setAvailableTimeslots] = useState([]);
  const [professionalService, setProfessionalService] = useState({});
  const [timesLoaded, setTimesLoaded] = useState(false);

  const [scheduleInfo, setScheduleInfo] = useState({});
  const [uploadModal, setUploadModal] = useState(false);
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    created_at: false,
    phone: false,
    userMail: false,
    price: false,
    rated: false,
    text_evaluation: false,
  });

  const TypeDocumentParse = {
    prescription: "RECEITA",
    report: "RELATÓRIO",
    certificate: "ATESTADO ",
    referral: "ENCAMINHAMENTO",
    exam: "SOLICITAÇÃO EXAME",
    medical: "PRONTUÁRIO",
  };

  const handleColumnVisibilityChange = (newModel) => {
    setColumnVisibilityModel(newModel);
  };

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  // Preparando constantes de configuração
  const availableDays = +process.env.REACT_APP_DAYS_AVALIABLE_TO_BUY;
  const actualDateTime = new Date();
  const limitDateTime = new Date();
  limitDateTime.setDate(actualDateTime.getDate() + availableDays);

  const convertCellPhone = (cellPhoneInput) =>
    cellPhoneInput
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");

  const clearAppointment = () => {
    setUserId("");
    setName("");
    setCpf("");
    setEmail("");
    setCellphone("");
    setNascimento("");
    setZip("");
    setAddress("");
    setNumber("");
    setNeighborhood("");
    setComplement("");
    setCity("");
    setState("");
    setProfessional("");
    setServiceId("");
    setCoupon("");
    setTimeSelected("");
    setAppointmentSelected("");
    setAvailableTimeslots([]);
    setProfessionalService({});
  };

  const clearAppointmentWithCPF = () => {
    setUserId("");
    setName("");
    setEmail("");
    setCellphone("");
    setNascimento("");
    setZip("");
    setAddress("");
    setNumber("");
    setNeighborhood("");
    setComplement("");
    setCity("");
    setState("");
    setProfessional("");
    setServiceId("");
    setCoupon("");
    setTimeSelected("");
    setAppointmentSelected("");
    setAvailableTimeslots([]);
    setProfessionalService({});
  };

  const handleCPF = (cpfInput) => {
    const cpfFormated = cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setCpf(cpfFormated);
  };

  const handleCellPhone = (cellPhoneInput) => {
    const cellPhoneFormated = cellPhoneInput
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
    setCellphone(cellPhoneFormated);
  };

  const isValidCPF = (numbersCPF) => {
    if (numbersCPF.length !== 11) {
      return false;
    }
    if (/^(\d)\1{10}$/.test(numbersCPF)) {
      return false;
    }

    let sum = 0;
    let remainder;
    for (let i = 1; i <= 9; i++) {
      sum += parseInt(numbersCPF[i - 1], 10) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(numbersCPF[9], 10)) {
      return false;
    }
    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(numbersCPF[i - 1], 10) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    return remainder === parseInt(numbersCPF[10], 10);
  };

  const downloadFile = async (fileId) => {
    const host = window.location.origin;
    window.open(`${host}/download/${fileId}`, "_blank");
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const cpfTratado = cpf.replace(/\D/g, "");
      if (cpfTratado.length === 11) {
        clearAppointmentWithCPF();
        const response = await api.get("/users", {
          params: {
            cpf: cpfTratado,
          },
        });
        if (response.status === 200) {
          const userSelected = response.data[0];
          setUserId(userSelected.id);
          setName(userSelected.name);
          setEmail(userSelected.email);
          setNascimento(userSelected.birth_date || "");
          setZip(userSelected.zip ?? "");
          setAddress(userSelected.address ?? "");
          setNumber(userSelected.number ?? "");
          setComplement(userSelected.complement ?? "");
          setNeighborhood(userSelected.neighborhood ?? "");
          setCity(userSelected.city ?? "");
          setState(userSelected.state ?? "");
          if (userSelected.cellphone) {
            handleCellPhone(userSelected.cellphone.substr(2));
          }
          if (userSelected.cpf) {
            handleCPF(userSelected.cpf);
          }
        }
      }
    } catch (error) {
      setAlertComponent({
        message: "Nenhum usuário com esse CPF, cadastre o novo usuário!",
        type: "error",
      });
      setUserId(null);
    }
    setLoading(false);
  };

  const insertUser = async () => {
    if (zip !== "") {
      if (city === "" || state === "" || address === "" || number === "") {
        setAlertComponent({
          message: "Erro nas informações de endereço",
          type: "error",
        });
        return null;
      }
    }

    if (!isValidCPF(cpf.replace(/\D/g, ""))) {
      setAlertComponent({
        message: "CPF inválido",
        type: "error",
      });
      return null;
    }

    if (!email.includes("@") || !email.includes(".")) {
      setAlertComponent({
        message: "E-mail inválido",
        type: "error",
      });
      return null;
    }

    if (cellphone.replace(/\D/g, "").length < 11) {
      setAlertComponent({
        message: "Celular inválido",
        type: "error",
      });
      return null;
    }

    if (nascimento === "") {
      setAlertComponent({
        message: "Data de nascimento inválida",
        type: "error",
      });
      return null;
    }

    setLoading(true);
    try {
      const response = await api.post("users", {
        name: name.trim(),
        cpf: cpf.replace(/\D/g, ""),
        birth_date: dayjs(nascimento).format("YYYY-MM-DD"),
        email: email.trim(),
        cellphone: `55${cellphone.replace(/\D/g, "")}`,
        password: cpf.replace(/\D/g, ""),
        zip,
        address,
        number,
        complement,
        neighborhood,
        city,
        state,
      });

      const token = response.data;
      const newUser = jose.decodeJwt(token).user;
      return newUser.id;
    } catch (error) {
      setAlertComponent({
        message: "Erro na inserção do usuário",
        type: "error",
      });
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getServices = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/services");
      setServices(data.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (err) {
      setAlertComponent({
        message: "Erro na extração dos serviços.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getProfessionals = async (serviceSelected) => {
    setLoading(true);
    try {
      const response = await api.get(
        `/professionals?service_id=${serviceSelected}`,
      );

      const sortedProfessionals = response.data.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setProfessionals(sortedProfessionals);
    } catch (err) {
      setAlertComponent({
        message: "Erro na extração dos dados dos profissionais.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getProfessional = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/professionals/${professional}/services/${serviceId}`,
      );
      const professionalSelected = response.data;
      setAvailableTimeslots(professionalSelected.timeAvailable);
      setProfessionalService(professionalSelected.service);
      setTimesLoaded(true);
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message || "Erro ao carregar profissional",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getAppointments = async () => {
    setLoading(true);
    try {
      const response = await api.get("/schedules", {
        params: {
          dateStart: dayjs()
            .tz("America/Sao_Paulo")
            .subtract(30, "day")
            .startOf("day"),
        },
      });
      const appointmentsNormalized = response.data.map((item) => ({
        ...item,
        professionalName: item.professional.name,
        serviceName: item.service.name,
        userName: item.user.name,
        userMail: item.user?.email,
      }));
      setAppointments(
        appointmentsNormalized.sort(
          (a, b) => new Date(b.date_start) - new Date(a.date_start),
        ),
      );
    } catch (err) {
      setAlertComponent({
        message: "Erro na extração dos agendamentos",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleOpenAppointmentDetails = async (id) => {
    setLoading(true);
    try {
      const response = await api.get(`/schedules/${id}`);
      setScheduleDetails(response.data);
      setOpenAppointmentDetailsModal(true);
    } catch (err) {
      setAlertComponent({
        message: "Erro na extração do agendamento",
        type: "error",
      });
    }
    setLoading(false);
  };

  const updateTimeSelected = (e) => {
    const stringDateTime = e.startTime
      .toString()
      .replace(/GMT[-+]\d{4}/, "GMT-0300")
      .replace(/\(.+?\)/, "(Horário de Brasília)");
    const timeFixed = dayjs(stringDateTime).tz("America/Sao_Paulo");
    const isoTime = dayjs(stringDateTime).toISOString();
    setTimeSelectedForBuy(isoTime);
    setTimeSelected(timeFixed);
  };

  const findZIP = (zipCode) => {
    setLoading(true);
    fetch(`https://viacep.com.br/ws/${zipCode}/json/`, { mode: "cors" })
      .then((res) => res.json())
      .then((data) => {
        if (data.erro) {
          alert("CEP não existente");
        } else {
          setAddress(data.logradouro);
          setNeighborhood(data.bairro);
          setCity(data.localidade);
          setState(data.uf);
        }
      })
      .catch((err) => console.error(err));
    setLoading(false);
  };

  const cleanAddress = () => {
    setAddress("");
    setNumber("");
    setNeighborhood("");
    setComplement("");
    setCity("");
    setState("");
  };

  const downloadMedicalReportWithoutSigned = async (appointmentSelected) => {
    setLoading(true);
    try {
      const userName =
        appointmentSelected?.user?.name.replace(" ", "_") || "paciente";
      const response = await api.get(
        `/schedules/${appointmentSelected.id}/download-medical-report`,
        { responseType: "blob" },
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `prontuario-${userName}-${appointmentSelected.id}.pdf`,
      );
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      setAlertComponent({
        message: "Erro ao baixar o prontuário",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const createAppointment = async () => {
    setLoading(true);
    try {
      let userIdSelected = userId;
      if (
        !userIdSelected &&
        cpf.replace(/\D/g, "").length === 11 &&
        name !== ""
      ) {
        userIdSelected = await insertUser();
      }

      if (!userIdSelected) {
        setAlertComponent({
          message: "Erro na busca do usuário.",
          type: "error",
        });
        return;
      }

      if (!serviceId || serviceId === "") {
        setAlertComponent({
          message: "Escolha um serviço",
          type: "error",
        });
        return;
      }

      if (!professional || professional === "") {
        setAlertComponent({
          message: "Escolha um profissional",
          type: "error",
        });
        return;
      }

      if (!timeSelectedForBuy || timeSelectedForBuy === "") {
        setAlertComponent({
          message: "Escolha um horário para atendimento",
          type: "error",
        });
        return;
      }

      if (coupon !== "") {
        const response = await api.get(
          `/coupons/validate?code=${coupon}&user_id=${userIdSelected}`,
        );
        if (response.status !== 200) {
          setAlertComponent({
            message: "Cupom inválido",
            type: "error",
          });
          return;
        }
      }

      const appointmentInputs = {
        user_id: userIdSelected,
        professional_id: professional,
        date_start: timeSelectedForBuy,
        service_id: serviceId,
        coupon,
        websiteRequest: true,
      };

      await api.post("/schedules", appointmentInputs);
      clearAppointment();
      setAlertComponent({
        message: "Agendamento criado com sucesso!",
        type: "success",
      });
    } catch (error) {
      if (error.status !== 500) {
        setAlertComponent({
          message:
            error?.response?.data?.message || "Erro na criação do agendamento.",
          type: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpenUploadModal = (row) => {
    setScheduleInfo(row);
    setUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setUploadModal(false);
    setScheduleInfo({});
    setFile(null);
    setDescription("");
  };

  const uploadNewFile = async () => {
    if (Object.keys(scheduleInfo).length === 0) {
      setAlertComponent({
        type: "error",
        message: "Agendamento não encontrado.",
      });
      setUploadModal(false);
      return;
    }

    if (!file) {
      setAlertModalInfos({
        type: "error",
        message: "Selecione o arquivo que deseja enviar",
      });
      return;
    }

    if (description === "") {
      setAlertModalInfos({
        type: "error",
        message: "Informe o tipo do arquivo no campo de texto",
      });
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("schedule_id", scheduleInfo.id);
      formData.append("description", description);

      await api.post(`/documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      handleCloseUploadModal();
      setAlertComponent({
        type: "success",
        message: "Arquivo salvo com sucesso",
      });
    } catch (error) {
      handleCloseUploadModal();
      setAlertComponent({
        type: "error",
        message: "Erro ao salvar arquivo",
      });
    }
    setLoading(false);
  };

  const extractCellPhone = (params) => {
    const numCell = convertCellPhone(params.row.user.phone.substring(2));
    return <span>{numCell}</span>;
  };

  const extractPrice = (params) => (
    <span>R$ {params.row.price.toFixed(2).replace(".", ",")}</span>
  );

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.2,
      headerClassName: "dataGrid-header",
    },
    {
      field: "created_at",
      headerName: "Criação",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      disableColumnMenu: true,
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
      renderCell: (params) =>
        dayjs(params.row.created_at).add(3, "hours").format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "date_start",
      headerName: "Atendimento",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      disableColumnMenu: true,
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
      renderCell: (params) =>
        dayjs(params.row.date_start).add(3, "hours").format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "userName",
      headerName: "Usuário",
      flex: 1,
      headerClassName: "dataGrid-header",
      disableColumnMenu: true,
    },
    {
      field: "phone",
      headerName: "Telefone",
      flex: 1,
      headerClassName: "dataGrid-header",
      disableColumnMenu: true,
      renderCell: extractCellPhone,
    },
    {
      field: "userMail",
      headerName: "E-mail",
      flex: 1,
      headerClassName: "dataGrid-header",
      disableColumnMenu: true,
    },
    {
      field: "serviceName",
      headerName: "Serviço",
      flex: 1,
      headerClassName: "dataGrid-header",
      disableColumnMenu: true,
    },
    {
      field: "price",
      headerName: "Preço",
      flex: 0.5,
      hide: true,
      headerClassName: "dataGrid-header",
      disableColumnMenu: true,
      renderCell: extractPrice,
    },
    {
      field: "professionalName",
      headerName: "Profissional",
      flex: 1,
      headerClassName: "dataGrid-header",
      disableColumnMenu: true,
    },
    {
      field: "rated",
      headerName: "Avaliação",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      disableColumnMenu: true,
    },
    {
      field: "text_evaluation",
      headerName: "Texto Avaliação",
      flex: 1,
      hide: true,
      headerClassName: "dataGrid-header",
      disableColumnMenu: true,
    },
    {
      field: "reportSigned",
      headerName: "Prontuário",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) =>
        row.reportSigned ? (
          <Typography variant="caption" fontWeight={700} color="green">
            ASSINADO
          </Typography>
        ) : (
          <Typography variant="caption" fontWeight={700} color="red">
            NÃO ASSINADO
          </Typography>
        ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      headerClassName: "dataGrid-header",
    },
    {
      field: "actions",
      headerName: "Detalhes",
      flex: 0.6,
      exportable: false,
      filtrable: false,
      sortable: false,
      headerClassName: "dataGrid-header",
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <MKBox>
          <Tooltip title="Detalhes do agendamento" placement="top">
            <IconButton
              size="large"
              onClick={() => handleOpenAppointmentDetails(row.id)}
            >
              <PreviewIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Enviar arquivo" placement="top">
            <IconButton size="large" onClick={() => handleOpenUploadModal(row)}>
              <CloudUploadIcon />
            </IconButton>
          </Tooltip>
          {["FINALIZADO", "AVALIAÇÃO"].includes(row.status) &&
            !row?.reportSigned && (
              <Tooltip
                title="Download de prontuário SEM ASSINATURA"
                placement="top-start"
                arrow
              >
                <IconButton
                  size="large"
                  onClick={() => downloadMedicalReportWithoutSigned(row)}
                >
                  <ArticleIcon />
                </IconButton>
              </Tooltip>
            )}
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    async function getInfos() {
      if (serviceId) {
        await getProfessionals(serviceId);
      }
    }
    getInfos();
  }, [serviceId]);

  useEffect(() => {
    async function getInfos() {
      if (professional) {
        await getProfessional();
      }
    }
    getInfos();
  }, [professional]);

  useEffect(() => {
    if (!authenticatedUser) {
      window.location.replace("/login");
    }
    async function getInfos() {
      setTimesLoaded(false);
      await Promise.all([getServices(), getAppointments()]);
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  useEffect(() => {
    if (alertModalInfos.message !== "") {
      setTimeout(() => {
        setAlertModalInfos({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertModalInfos]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox
          component="section"
          position="relative"
          py={20}
          width="100%"
          height="100vh"
        >
          <Grid
            container
            alignItems="top"
            sx={{ display: "flex", alignContent: "stretch" }}
          >
            <Grid item xs={12} lg={12} py={3}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={3}
                  mb={1}
                  textAlign="center"
                >
                  <MKTypography
                    variant="h4"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    {appointmentSelected
                      ? "Edição de agendamento"
                      : "Criar novo agendamento"}
                  </MKTypography>
                  {!appointmentSelected ||
                    (!userId && (
                      <MKBox>
                        <MKTypography variant="button" color="white">
                          CPF e Nome são campos obrigatórios e você deve
                          informar e-mail ou telefone para notificarmos o
                          usuário sobre o agendamento. Quando o CPF atingir 11
                          números, o sistema sistema irá buscar o usuário e
                          preencherá os campos restantes e, caso não encontre,
                          criará um novo usuário com a senha exatamente igual ao
                          CPF do usuário.
                        </MKTypography>
                      </MKBox>
                    ))}
                </MKBox>
                {alertComponent.message && (
                  <Grid item xs={12} p={2}>
                    <MKAlert color={alertComponent.type}>
                      {alertComponent.message}
                    </MKAlert>
                  </Grid>
                )}
                <MKBox mx={2} mb={1} pt={1}>
                  <MKBox width="100%" p={1}>
                    <MKTypography variant="h5">Dados:</MKTypography>
                  </MKBox>
                  <MKBox width="100%" py={1}>
                    <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                      <MKBox width="100%" p={1}>
                        <MKInput
                          variant="outlined"
                          label="CPF"
                          name="cpf"
                          value={cpf}
                          onChange={(e) => handleCPF(e.target.value)}
                          onBlur={getUser}
                          fullWidth
                          required
                        />
                      </MKBox>
                      <MKBox width="100%" p={1}>
                        <MKInput
                          variant="outlined"
                          label="Nome"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          fullWidth
                          required
                        />
                      </MKBox>
                    </Grid>
                    <Grid item>
                      <MKBox width="100%" p={1}>
                        <MKInput
                          variant="outlined"
                          label="E-mail"
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          fullWidth
                        />
                      </MKBox>
                    </Grid>
                    <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                      <MKBox width="100%" p={1}>
                        <MKInput
                          variant="outlined"
                          label="Celular"
                          name="cellphone"
                          value={cellphone}
                          onChange={(e) => handleCellPhone(e.target.value)}
                          fullWidth
                        />
                      </MKBox>
                      <MKBox width="100%" p={1}>
                        <MKInput
                          variant="outlined"
                          label="Nacimento"
                          type="date"
                          name="nascimento"
                          value={nascimento}
                          onChange={(e) => setNascimento(e.target.value)}
                          fullWidth
                        />
                      </MKBox>
                    </Grid>
                  </MKBox>
                  <MKBox width="100%" py={1}>
                    <MKBox width="100%" p={1}>
                      <MKTypography variant="h5">Endereço:</MKTypography>
                    </MKBox>
                    <MKBox width="100%" p={1}>
                      <MKInput
                        variant="outlined"
                        name="zip"
                        label="CEP"
                        value={zip}
                        onChange={(e) => {
                          setZip(e.target.value);
                          cleanAddress();
                        }}
                        onBlur={() => findZIP(zip)}
                        fullWidth
                      />
                    </MKBox>
                    <MKBox width="100%" p={1}>
                      <MKInput
                        variant="outlined"
                        name="address"
                        label="Logradouro"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        fullWidth
                      />
                    </MKBox>
                    <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                      <MKBox width="100%" p={1}>
                        <MKInput
                          variant="outlined"
                          name="number"
                          label="Número"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          fullWidth
                        />
                      </MKBox>
                      <MKBox width="100%" p={1}>
                        <MKInput
                          variant="outlined"
                          name="neighborhood"
                          label="Bairro"
                          value={neighborhood}
                          onChange={(e) => setNeighborhood(e.target.value)}
                          fullWidth
                        />
                      </MKBox>
                    </Grid>
                    <MKBox width="100%" p={1}>
                      <MKInput
                        variant="outlined"
                        name="complement"
                        label="Complemento"
                        value={complement}
                        onChange={(e) => setComplement(e.target.value)}
                        fullWidth
                      />
                    </MKBox>
                    <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                      <MKBox width="100%" p={1}>
                        <MKInput
                          variant="outlined"
                          name="city"
                          label="Cidade"
                          value={city}
                          fullWidth
                        />
                      </MKBox>
                      <MKBox width="100%" p={1}>
                        <MKInput
                          variant="outlined"
                          name="state"
                          label="Estado"
                          value={state}
                          fullWidth
                        />
                      </MKBox>
                    </Grid>
                  </MKBox>
                  <MKBox width="100%" py={1}>
                    <MKBox width="100%" p={1}>
                      <MKTypography variant="h5">
                        Escolha o serviço:
                      </MKTypography>
                    </MKBox>
                    <MKBox width="100%" p={1}>
                      <FormControl fullWidth>
                        <InputLabel id="service">Serviço</InputLabel>
                        <Select
                          variant="outlined"
                          labelId="service"
                          id="service"
                          value={serviceId}
                          label="Serviço"
                          sx={{ height: "45px" }}
                          onChange={(e) => setServiceId(e.target.value)}
                          required
                        >
                          {Object.keys(services).length > 0 &&
                            services.map((service) => (
                              <MenuItem key={service.id} value={service.id}>
                                {service.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </MKBox>
                  </MKBox>
                  {Object.keys(services).length > 0 && (
                    <MKBox width="100%" py={1}>
                      <MKBox width="100%" p={1}>
                        <MKTypography variant="h5">Profissional:</MKTypography>
                      </MKBox>
                      <MKBox width="100%" p={1}>
                        <FormControl fullWidth>
                          <InputLabel id="professional">
                            Profissional
                          </InputLabel>
                          <Select
                            variant="outlined"
                            labelId="professional"
                            id="professional"
                            value={professional}
                            label="Profissional"
                            sx={{ height: "45px" }}
                            onChange={(e) => setProfessional(e.target.value)}
                            required
                          >
                            {professionals &&
                              professionals.length > 0 &&
                              professionals.map((professionalDB) => (
                                <MenuItem
                                  key={professionalDB.id}
                                  value={professionalDB.id}
                                >
                                  {professionalDB.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </MKBox>
                    </MKBox>
                  )}
                  {timesLoaded && availableTimeslots.length > 0 && (
                    <Grid container width="100%">
                      <MKBox width="100%">
                        <ScheduleMeeting
                          borderRadius={10}
                          primaryColor="#3f5b85"
                          eventDurationInMinutes={
                            professionalService?.duration !== 0
                              ? professionalService.duration
                              : 60
                          }
                          availableTimeslots={availableTimeslots}
                          onStartTimeSelect={updateTimeSelected}
                          // Language props
                          locale={datePtBr}
                          lang_cancelButtonText="Cancelar"
                          lang_confirmButtonText="Confirmar"
                          lang_emptyListText="Nenhum horário disponível"
                          lang_goToNextAvailableDayText="Próximo Dia"
                          lang_noFutureTimesText="Nenhum horário futuro disponível"
                          // Date format props
                          format_locale={datePtBr}
                          format_nextFutureStartTimeAvailableFormatString="P"
                          format_selectedDateDayTitleFormatString="P"
                          format_selectedDateMonthTitleFormatString="LLLL yyyy"
                          format_startTimeFormatString="HH:mm"
                        />
                      </MKBox>
                    </Grid>
                  )}
                  {timeSelected && (
                    <Grid item xs={12} md={12}>
                      <MKTypography variant="body1" textAlign="center">
                        Horário Escolhido:{" "}
                        {dayjs(timeSelected).format(
                          "dddd DD [de] MMMM [de] YYYY [às] HH:mm[h] [(Horário de Brasília)]",
                        )}
                      </MKTypography>
                      <MKInput
                        variant="outlined"
                        name="coupon"
                        label="CÓDIGO DO CUPOM"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        fullWidth
                        sx={{ paddingX: 1 }}
                        autoFocus
                      />
                    </Grid>
                  )}
                  {alertComponent.message && (
                    <Grid item xs={12} p={2}>
                      <MKAlert color={alertComponent.type}>
                        {alertComponent.message}
                      </MKAlert>
                    </Grid>
                  )}
                  <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                    <MKBox width="100%" p={2}>
                      <MKButton
                        variant="gradient"
                        color="info"
                        onClick={clearAppointment}
                        fullWidth
                      >
                        Limpar Dados
                      </MKButton>
                    </MKBox>
                    <MKBox width="100%" p={2}>
                      {loading || !timeSelected || cpf === "" || name === "" ? (
                        <MKButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          disabled
                        >
                          Agendar Atendimento
                        </MKButton>
                      ) : (
                        <MKButton
                          variant="gradient"
                          color="info"
                          onClick={createAppointment}
                          fullWidth
                        >
                          Agendar Atendimento
                        </MKButton>
                      )}
                    </MKBox>
                  </Grid>
                </MKBox>
              </Card>
            </Grid>

            <Grid
              item
              xs={12}
              lg={12}
              py={3}
              style={{ height: "800px", width: "100%" }}
            >
              {appointments && (
                <DataGrid
                  rows={appointments}
                  columns={columns}
                  density="compact"
                  disableRowSelectionOnClick
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: GridToolbar }}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={handleColumnVisibilityChange}
                />
              )}
            </Grid>
          </Grid>
        </MKBox>
      </Container>

      <Dialog
        open={openAppointmentDetailsModal}
        onClose={() => setOpenAppointmentDetailsModal(false)}
        scroll="body"
        aria-labelledby="times-avaliable-title"
        aria-describedby="times-avaliable-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle
          id="times-avaliable-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Detalhes do Atendimento</h3>
          </div>
          <div>
            <IconButton onClick={() => setOpenAppointmentDetailsModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container p={2}>
            {scheduleDetails && Object.keys(scheduleDetails).length > 0 && (
              <>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="body1">
                    <strong>Data Atendimento:</strong>{" "}
                    {dayjs(scheduleDetails.date_start)
                      .add(3, "hours")
                      .format("DD/MM/YYYY [às] HH:mm")}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Paciente:</strong> {scheduleDetails?.user?.name}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Serviço:</strong> {scheduleDetails?.service?.name}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Status:</strong> {scheduleDetails.status}
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  {scheduleDetails?.professional && (
                    <Grid item xs={12} md={12}>
                      <Box
                        sx={{
                          border: "1px solid #f0f0f0",
                          borderRadius: "10px",
                          padding: "10px",
                          marginTop: "10px",
                          height: "100%",
                        }}
                      >
                        <Typography variant="body1">
                          <strong>Profissional:</strong>{" "}
                          {scheduleDetails.professional.name}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Documento:</strong>{" "}
                          {scheduleDetails.professional?.resume ||
                            "Não informado"}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
                {scheduleDetails?.rating && (
                  <Box
                    sx={{
                      border: "1px solid #f0f0f0",
                      borderRadius: "10px",
                      padding: "10px",
                      marginTop: "10px",
                      height: "100%",
                    }}
                  >
                    <Typography variant="body1">
                      Atendimento avaliado com{" "}
                      <strong>{scheduleDetails.rating}</strong> estrelas
                    </Typography>
                    <Typography variant="body2">
                      <strong>Comentário:</strong> {scheduleDetails.comment}
                    </Typography>
                  </Box>
                )}
                {scheduleDetails?.attachments &&
                  scheduleDetails.attachments.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        flexWrap: "wrap",
                        border: "1px solid #f0f0f0",
                        borderRadius: "10px",
                        padding: "10px",
                        marginY: "20px",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      {scheduleDetails.attachments.map((file) => (
                        <Tooltip
                          key={file.uuid}
                          title="Baixar arquivo"
                          placement="top-start"
                          arrow
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignContent: "center",
                              alignItems: "center",
                              border: "1px solid #f0f0f0",
                              borderRadius: "10px",
                              backgroundColor: "#f0f0f0",
                              padding: "10px",
                              width: "200px",
                              height: "100px",
                              margin: "10px",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => downloadFile(file.uuid)}
                          >
                            <Box sx={{ height: "50px" }}>
                              <DownloadForOfflineIcon
                                fontSize="large"
                                height="50px"
                              />
                            </Box>
                            <Typography
                              variant="body2"
                              lineHeight={1.2}
                              sx={{
                                width: "100%",
                                textWrap: "wrap",
                                overflowWrap: "break-word",
                              }}
                            >
                              {file?.description !== "" &&
                              Object.keys(TypeDocumentParse).some((key) =>
                                file.description.startsWith(key),
                              )
                                ? TypeDocumentParse[
                                    file.description.split("_")[0]
                                  ]
                                : file.description}
                            </Typography>
                          </Box>
                        </Tooltip>
                      ))}
                    </Box>
                  )}
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>

      {Object.keys(scheduleInfo).length > 0 && (
        <Dialog
          open={uploadModal}
          onClose={handleCloseUploadModal}
          maxWidth="md"
          fullWidth
          sx={{ zIndex: 20000 }}
        >
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <h3>Upload de Arquivo</h3>
            </div>
            <div>
              <IconButton onClick={handleCloseUploadModal}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers>
            {alertModalInfos.message !== "" && (
              <Box sx={{ padding: "10px" }}>
                <Alert severity={alertModalInfos.type}>
                  {alertModalInfos.message}
                </Alert>
              </Box>
            )}
            <Box
              sx={{
                padding: 2,
                backgroundColor: "#FFEDE9",
                borderRadius: "10px",
              }}
            >
              <Typography variant="body1" fontWeight="700">
                CONFIRA OS DADOS DO ATENDIMENTO:
              </Typography>
              <Typography variant="body2">
                NOME DO PACIENTE: {scheduleInfo.user.name}
              </Typography>
              <Typography variant="body2">
                NOME DO PROFISSIONAL: {scheduleInfo.professional.name}
              </Typography>
              <Typography variant="body2">
                HORA DO ATENDIMENTO:{" "}
                {dayjs(scheduleInfo.date_start)
                  .add(3, "hours")
                  .tz("America/Sao_Paulo")
                  .format("DD/MM/YYYY [às] HH:mm")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", padding: 1 }}>
              <FormControl>
                <FormLabel>DESCRIÇÃO:</FormLabel>
                <TextField
                  onChange={(e) => setDescription(e.target.value)}
                  helperText="Informe uma descrição"
                  hel
                  sx={{
                    height: "40px",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                />
              </FormControl>
              <TextField
                type="file"
                id="file"
                helperText="Selecione o arquivo que deseja enviar"
                onChange={(e) => setFile(e.target.files[0])}
                sx={{ marginBottom: "20px", marginTop: "20px" }}
              />
              <Button
                variant="contained"
                color="success"
                onClick={() => uploadNewFile()}
              >
                Enviar Arquivo
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default AppointmentsAdminPanel;
