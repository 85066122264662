import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-unresolved
import { useRoomConnection } from "@whereby.com/browser-sdk/react";
import { Box, Typography, useMediaQuery } from "@mui/material";

import IconButton from "./IconButton";

function MeetingRoomComponent(props) {
  const { roomUrl, localUserName, finalizeAppointment } = props;

  MeetingRoomComponent.propTypes = {
    roomUrl: PropTypes.string.isRequired,
    localUserName: PropTypes.string.isRequired,
    finalizeAppointment: PropTypes.func.isRequired,
  };

  const isDesktop = useMediaQuery("(min-width:768px)");

  const [meetingAuthorization, setMeetingAuthorization] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(true);
  const [isMicrophoneActive, setIsMicrophoneActive] = useState(true);

  const roomConnection = useRoomConnection(roomUrl, {
    displayName: localUserName,
    localMediaOptions: {
      audio: true,
      video: true,
    },
  });

  const { actions, components, state } = roomConnection;
  const { VideoView } = components;
  const { localParticipant, remoteParticipants } = state;
  const { toggleCamera, toggleMicrophone } = actions;

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      roomConnection.actions.leaveRoom();
      event.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [roomConnection.actions, localParticipant]);

  useEffect(() => {
    if (
      !meetingAuthorization &&
      localParticipant &&
      remoteParticipants.length > 0
    ) {
      setMeetingAuthorization(true);
    } else if (meetingAuthorization && remoteParticipants.length === 0) {
      setMeetingAuthorization(false);
    }
  }, [localParticipant, remoteParticipants, meetingAuthorization]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          flexWrap: "wrap",
          width: "100%",
          padding: "10px",
          height: "100vh",
          backgroundColor: "#000000",
        }}
      >
        {meetingAuthorization &&
          remoteParticipants?.length > 0 &&
          // eslint-disable-next-line array-callback-return
          remoteParticipants.map((participant) => (
            <Box
              key={participant.id}
              sx={{
                position: "relative",
                display: "flex",
                width:
                  remoteParticipants?.length > 1
                    ? `${100 / remoteParticipants.length}%`
                    : "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: participant?.isVideoEnabled
                    ? ""
                    : "#cecece50",
                }}
              >
                {participant.stream ? (
                  <VideoView
                    stream={participant.stream}
                    style={{
                      borderRadius: "20px",
                      width: "100%",
                      maxHeight: window.innerHeight - 40,
                      backgroundColor: "#000000",
                    }}
                  />
                ) : (
                  <Typography variant="body1">Sem vídeo!</Typography>
                )}
                <Typography
                  variant="h6"
                  sx={{
                    width: "100%",
                    color: "#ffffff",
                    position: "absolute",
                    bottom: 10,
                    left: 20,
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
                  }}
                >
                  {participant?.displayName || ""}
                </Typography>
              </Box>
            </Box>
          ))}
        {remoteParticipants?.length === 0 && (
          <Typography variant="h6" sx={{ color: "#ffffff" }}>
            Aguardando entrada do outro usuário...
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          position: isDesktop ? "fixed" : "absolute",
          bottom: "40px",
          width: isDesktop ? "370px" : "auto",
          height: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          paddingY: "30px",
          paddingX: isDesktop ? "0px" : "100px",
          right: isDesktop ? "0px" : "",
          marginBottom: isDesktop ? "15px" : "0px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: isCameraActive ? "" : "#cecece50",
            maxHeight: isDesktop ? "150px" : "100px",
          }}
        >
          {localParticipant?.stream && isCameraActive ? (
            <VideoView
              muted
              stream={localParticipant.stream}
              style={{ borderRadius: "20px" }}
            />
          ) : (
            <Typography variant="body1">Sem vídeo!</Typography>
          )}
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: "0px",
            right: "0px",
            width: isDesktop ? "auto" : "100%",
            paddingX: isDesktop ? "100px" : "10px",
            marginBottom: "20px",
            zIndex: 2000,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "#fff",
              width: "100%",
              padding: 1,
              borderRadius: "20px",
              gap: "20px",
              boxShadow: "0px 1px 4px 0px #00000029",
            }}
          >
            <IconButton
              variant="camera"
              aria-label="Ativar/desativar câmera"
              isActive={isCameraActive}
              onClick={() => {
                setIsCameraActive((prev) => !prev);
                toggleCamera();
              }}
            />
            <IconButton
              variant="microphone"
              aria-label="Ativar/desativar microfone"
              isActive={isMicrophoneActive}
              onClick={() => {
                setIsMicrophoneActive((prev) => !prev);
                toggleMicrophone();
              }}
            />
            <IconButton
              variant="close"
              aria-label="Fechar sala de atendimento"
              onClick={() => finalizeAppointment()}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MeetingRoomComponent;
