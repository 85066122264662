/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { AuthProvider } from "Context/AuthContext";
import { Helmet } from "react-helmet";

import { Switch, Route, useHistory, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";

import { Typography } from "@mui/material";

import Home from "pages/Home";
import Psychiatrist from "pages/Psychiatrist";
import Psychologists from "pages/Psychologists";
import ProfessionalDetails from "pages/ProfessionalDetails";
import Register from "pages/Register";
import ForgotPassword from "pages/ForgotPassword";
import Privacy from "pages/Privacy";
import Confirmation from "pages/Confirmation";
import UserArea from "pages/UserArea";
import AppointmentDetails from "pages/AppointmentDetails";
import Logout from "pages/Logout";
import MeetingRoom from "pages/MeetingRoom";
import OldMeetingRoom from "pages/OldMeetingRoom";
import Payment from "pages/Payment";
// import ProfessionalArea from "pages/ProfessionalArea";
import ProfessionalAppointmentDetails from "pages/ProfessionalAppointmentDetails";
import Questions from "pages/Questions";
import AdministratorArea from "pages/Administrator";
import QuestionsToAnswer from "pages/QuestionsToAnswer";
import PackagePayment from "pages/PackagePayment";
import FAQ from "pages/FAQ";
import ProfessionalAdminPanel from "pages/Administrator/Professionals";
import Coupons from "pages/Administrator/Coupons";
import UserAdminPanel from "pages/Administrator/Users";
import AppointmentsAdminPanel from "pages/Administrator/Appointmets";
import ProfessionalAppointments from "pages/ProfessionalAppointments";
import UserAppointments from "pages/UserAppointments";
import MedicalRecords from "pages/MedicalRecords";
import AboutUs from "pages/AboutUs";
import HealthPlan from "pages/HealthPlan";
import Canabidiol from "pages/Canabidiol";
import DeleteMyInfos from "pages/DeleteMyInfos";
import AdminQuestions from "pages/Administrator/Questions";
import MensagensPush from "pages/Administrator/MensagensPush";
import Unavaliable from "pages/Unavaliable";
import Marketing from "pages/Administrator/Marketing";
import SchedulesSales from "pages/Administrator/SchedulesSales";
import StorePage from "pages/StorePage";
import ProductDetails from "pages/ProductDetails";
import StorePayment from "pages/StorePayment";
import Store from "pages/Administrator/Store";
import StoreGroups from "pages/Administrator/StoreGroups";
import StoreSales from "pages/Administrator/StoreSales";
import ProfessionalQuickServices from "pages/ProfessionalQuickServices";
import AdminQuickServices from "pages/Administrator/QuickServices";
import AdminMPOPlanUsers from "pages/Administrator/MPOPlanUsers";
import Purchases from "pages/Purchases";
import DownloadPage from "pages/DownloadPage";

import MKBox from "components/MKBox";

import whatsapp from "assets/Fale_WhatsApp.png";

import Cookies from "js-cookie";
import * as jose from "jose";
import PlayerVideo from "pages/PlayerVideo";
import Clinical from "pages/Clinical";
import FileDownloadPage from "./pages/FileDownload";
import SplitAccounts from "./pages/Administrator/SplitAccounts";
import Login from "./pages/Login";
import { api } from "./lib/axios";
import DispenserPage from "./pages/Dispenser";
import Prescriptions from "./pages/Administrator/Prescriptions";

export default function App() {
  const { pathname } = useLocation();

  const [whatsAppProps, setWhatsAppProps] = useState({});

  function isTokenExpired(token) {
    if (token) {
      const decodedToken = jose.decodeJwt(token);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      return decodedToken.exp < currentTimestamp;
    }
    return false;
  }

  const history = useHistory();
  const utm = window.location.href;

  const [homologation, setHomologation] = useState("");

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const getHomologationState = async () => {
    const response = await api.get("/getEnv");
    if (response.data.env === "development") {
      setHomologation(response.data.message);
    }
  };

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (isTokenExpired(token)) {
      history.push("/logout");
    }
  }, [token]);

  useEffect(() => {
    if (pathname === "/player-video") {
      setWhatsAppProps({
        display: "none",
      });
    } else {
      setWhatsAppProps({
        position: "fixed",
        width: "200px",
        bottom: "0px",
        left: "0px",
        zIndex: 1000,
      });
    }
  }, [pathname]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const target = urlParams.get("target");
    if (target) {
      history.push(target);
    }
    async function getInfos() {
      const storageInfos = localStorage.getItem("utm");
      const utmSplit = utm?.split("?")[1] || "";
      if (utmSplit !== "" && storageInfos !== utmSplit) {
        localStorage.setItem("utm", utmSplit);
      }
      async function handleToken() {
        if (window.location.hash === "#_=_") window.location.hash = "";

        // Verifique se há um token na URL
        const urlParams = new URLSearchParams(window.location.search);
        const tokenFromUrl = urlParams.get("token");

        // Se o token estiver na URL ou no cookie, armazene-o no armazenamento local e configure o cabeçalho de autorização
        const token = tokenFromUrl || Cookies.get("x-auth-cookie");

        if (token) {
          if (tokenFromUrl) {
            // Remova o token da URL
            urlParams.delete("token");
            window.history.replaceState(
              {},
              "",
              `${window.location.pathname}?${urlParams.toString()}`,
            );
          } else {
            // Cookies.remove("x-auth-cookie");
          }

          localStorage.setItem("token", JSON.stringify(token));

          const { user } = jose.decodeJwt(token);

          if (user.type === 1) {
            user.type = "admin";
          } else if (user.type === 3) {
            user.type = "professional";
          } else if (user.type === 4) {
            user.type = "supervisor";
          } else {
            user.type = "user";
          }

          localStorage.setItem("user", JSON.stringify(user));
          api.defaults.headers.Authorization = `Bearer ${token}`;
        }
      }

      handleToken();
      getHomologationState();
    }
    getInfos();
  }, []);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route
            path="/professionaldetails/:professionalId"
            component={ProfessionalDetails}
          />
          <Route path="/cbd" component={Canabidiol} />
          <Route path="/healthplan/:serviceId" component={HealthPlan} />
          <Route path="/healthplan" component={HealthPlan} />
          <Route path="/psychiatrists" component={Psychiatrist} />
          <Route path="/psychologists" component={Psychologists} />
          <Route path="/clinical" component={Clinical} />
          <Route path="/store" component={StorePage} />
          <Route path="/productdetails/:id" component={ProductDetails} />
          <Route path="/register" component={Register} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/privacy-politic" component={Privacy} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/faq" component={FAQ} />
          <Route path="/login" component={Login} />
          <Route path="/confirmation/:id" component={Confirmation} />
          <Route path="/logout" component={Logout} />
          <Route path="/user-area" component={UserArea} />
          <Route path="/user-appointments" component={UserAppointments} />
          <Route path="/user-purchases/:productId" component={Purchases} />
          <Route path="/user-purchases" component={Purchases} />
          {/* <Route path="/professional-area" component={ProfessionalArea} /> */}
          <Route path="/deletemyinfos" component={DeleteMyInfos} />
          <Route path="/prefessional-unavaliable" component={Unavaliable} />
          <Route
            path="/professional-appointments"
            component={ProfessionalAppointments}
          />
          <Route
            path="/professional-quick-services"
            component={ProfessionalQuickServices}
          />
          <Route
            exact
            path="/administrator-area/quick-services"
            component={AdminQuickServices}
          />
          <Route
            exact
            path="/administrator-area/mpo-plan-users"
            component={AdminMPOPlanUsers}
          />
          <Route
            exact
            path="/administrator-area/professionals"
            component={ProfessionalAdminPanel}
          />
          <Route exact path="/administrator-area/coupons" component={Coupons} />
          <Route
            exact
            path="/administrator-area"
            component={AdministratorArea}
          />
          <Route
            exact
            path="/administrator-area/appointments"
            component={AppointmentsAdminPanel}
          />
          <Route
            exact
            path="/administrator-area/professionals"
            component={ProfessionalAdminPanel}
          />
          <Route
            exact
            path="/administrator-area/users"
            component={UserAdminPanel}
          />
          <Route
            exact
            path="/administrator-area/questions"
            component={AdminQuestions}
          />
          <Route
            exact
            path="/administrator-area/push"
            component={MensagensPush}
          />
          <Route
            exact
            path="/administrator-area/marketing"
            component={Marketing}
          />
          <Route
            exact
            path="/administrator-area/schedules-sales"
            component={SchedulesSales}
          />
          <Route exact path="/administrator-area/coupons" component={Coupons} />
          <Route exact path="/administrator-area/store" component={Store} />
          <Route
            exact
            path="/administrator-area/store-sales"
            component={StoreSales}
          />
          <Route
            exact
            path="/administrator-area/store-groups"
            component={StoreGroups}
          />
          <Route
            exact
            path="/administrator-area"
            component={AdministratorArea}
          />
          <Route
            exact
            path="/administrator-area/prescriptions"
            component={Prescriptions}
          />
          <Route
            exact
            path="/administrator-area/split-accounts"
            component={SplitAccounts}
          />
          <Route path="/questions" component={Questions} />
          <Route path="/answer-questions" component={QuestionsToAnswer} />
          <Route
            exact
            path="/appointment-details/:id"
            component={AppointmentDetails}
          />
          <Route
            exact
            path="/professional-appointment-details/:id"
            component={ProfessionalAppointmentDetails}
          />
          <Route exact path="/meeting-room/:id" component={MeetingRoom} />
          <Route
            exact
            path="/meeting-room-legancy/:id"
            component={OldMeetingRoom}
          />
          <Route exact path="/medical-records/:id" component={MedicalRecords} />
          <Route exact path="/payment/:id" component={Payment} />
          <Route exact path="/storepayment/:id" component={StorePayment} />
          <Route exact path="/package-payment/:id" component={PackagePayment} />
          <Route exact path="/player-video" component={PlayerVideo} />
          <Route exact path="/download/:id" component={DownloadPage} />
          <Route exact path="/file/:id" component={FileDownloadPage} />
          <Route exact path="/dispenser/:id" component={DispenserPage} />
        </Switch>
        {/* {notification && (
          <MKSnackbar
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            icon="notifications"
            title="Notificação"
            content={notification}
            open={!!notification}
            close={() => closeNotification()}
            dateTime=""
          />
        )} */}
        {isMobile && (
          <MKBox
            sx={{
              ...whatsAppProps,
            }}
          >
            <a
              href={`https://wa.me/${process.env.REACT_APP_WHATSAPP_ADMIN}`}
              target="_blank"
            >
              <MKBox
                component="img"
                src={whatsapp}
                alt="Meu Psiquiatra Online"
                width="80%"
              />
            </a>
          </MKBox>
        )}
        {!isMobile && (
          <Helmet>
            <script type="text/javascript">
              {`
                var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                (function(){
                  var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                  s1.async=true;
                  s1.src='https://embed.tawk.to/666c8c8f9a809f19fb3dd82b/1i7jp1edq';
                  s1.charset='UTF-8';
                  s1.setAttribute('crossorigin','*');
                  s0.parentNode.insertBefore(s1,s0);
                })();
              `}
            </script>
          </Helmet>
        )}
        {homologation !== "" && (
          <MKBox
            sx={{
              position: "fixed",
              bottom: "0px",
              width: "100%",
              backgroundColor: "red",
              padding: "10px",
              zIndex: 50000,
            }}
          >
            <Typography sx={{ color: "yellow", textAlign: "center" }}>
              {homologation}
            </Typography>
          </MKBox>
        )}
      </ThemeProvider>
    </AuthProvider>
  );
}
