import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import OtpInput from "react18-input-otp";
import MKAlert from "../MKAlert";
import { api } from "../../lib/axios";

function DocumentGenerator(params) {
  const { appointmentInfos, closeModal } = params;
  const [alertComponent, setAlertComponent] = useState({
    type: "",
    message: "",
  });

  const [documentText, setDocumentText] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState("PRESCRIPTION");

  const TypeDocumentEnum = {
    PRESCRIPTION: "Prescrição",
    REPORT: "Relatório",
    CERTIFICATE: "Atestado",
    REFERRAL: "Encaminhamento",
    EXAM_REQUEST: "Solicitação de Exame",
  };

  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
    ],
  };

  const formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const generatePrescription = async () => {
    if (otpCode === "" || otpCode.length < 6) {
      setAlertComponent({
        type: "error",
        message: "Código de autenticação inválido",
      });
      return;
    }

    if (!appointmentInfos?.id) {
      setAlertComponent({
        type: "error",
        message: "Selecione um agendamento",
      });
      return;
    }

    if (documentText === "") {
      setAlertComponent({
        type: "error",
        message: "Informe a prescrição",
      });
      return;
    }

    setAlertComponent({
      message: "Gerando documento... Aguarde! ",
      type: "info",
    });

    setLoading(true);
    try {
      const dataRequest = {
        temporaryCode: otpCode,
        text: documentText,
        type: documentType,
      };
      await api.post(
        `/schedules/${appointmentInfos.id}/prescription`,
        dataRequest,
      );
      setAlertComponent({
        type: "success",
        message: "Documento gerado com sucesso",
      });
    } catch (error) {
      setAlertComponent({
        type: "error",
        message: error?.message || "Erro ao gerar documento",
      });
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Processando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <ReactQuill
        id="documentInput"
        theme="snow"
        modules={modules}
        formats={formats}
        placeholder="Insira o texto aqui..."
        value={documentText}
        onChange={(content) => setDocumentText(content)}
        style={{
          height: `${window.innerHeight - 500}px`,
          marginBottom: "50px",
        }}
      />
      {alertComponent.message !== "" && (
        <Box sx={{ marginY: 1 }}>
          <MKAlert color={alertComponent.type}>
            {alertComponent.message}
          </MKAlert>
        </Box>
      )}
      <Box sx={{ padding: "10px" }}>
        <FormControl>
          <FormLabel id="documentTypeSelection">Tipo do documento:</FormLabel>
          <RadioGroup
            row
            aria-labelledby="documentTypeSelection"
            name="position"
            defaultValue="top"
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
          >
            {Object.keys(TypeDocumentEnum).map((key) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio />}
                label={TypeDocumentEnum[key]}
                checked={documentType === key}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          border: "1px solid #ccc",
        }}
      >
        <Box sx={{ padding: "10px" }}>
          <InputLabel htmlFor="otpCode">Código de Validação BirdID</InputLabel>
        </Box>
        <OtpInput
          id="otpCode"
          numInputs={6}
          value={otpCode}
          onChange={(value) => setOtpCode(value)}
          inputStyle={{
            width: "100px",
            height: "80px",
            margin: "5px",
            fontSize: "20px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
      </Box>
      <Button
        type="submit"
        variant="contained"
        onClick={() => generatePrescription()}
        sx={{
          width: "100%",
          marginY: 1,
        }}
      >
        <Typography variant="buttom" sx={{ color: "#ffffff" }}>
          Gerar Documento
        </Typography>
      </Button>
    </>
  );
}

export default DocumentGenerator;
