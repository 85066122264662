import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import logo from "assets/images/logo_horizontal.png";

import { api } from "../../lib/axios";

function FileDownloadPage() {
  const { id } = useParams();

  const [downloadMessage, setDownloadMessage] = useState(
    "Fazendo download do arquivo...",
  );

  const downloadFile = async () => {
    if (!id || id === "") {
      setDownloadMessage("Arquivo não informado");
      return;
    }
    try {
      const { data } = await api.get(`/file/${id}`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `receita.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      setDownloadMessage("Erro ao buscar dados do arquivo");
    }
  };

  useEffect(() => {
    downloadFile();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        marginBottom: "80px",
      }}
    >
      <img src={logo} alt="Logo" style={{ height: "100px", width: "auto" }} />
      {downloadMessage !== "" && (
        <Typography variant="h5">{downloadMessage}</Typography>
      )}
    </Box>
  );
}

export default FileDownloadPage;
