import React, { useEffect, useState } from "react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";

function EletronicMedicalRecord(params) {
  const { saveMedicalRecord, appointmentInfos } = params;

  const [loading, setLoading] = useState(false);
  const [alertInfos, setAlertInfos] = useState({
    type: "",
    message: "",
  });
  const [alertModalInfos, setAlertModalInfos] = useState({
    type: "",
    message: "",
  });

  const [toggleWindow, setToggleWindow] = useState(false);
  const [documentText, setDocumentText] = useState("");

  const handleDrawer = () => {
    setToggleWindow(!toggleWindow);
  };

  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
    ],
  };

  const formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const submitMedicalRecord = async () => {
    setLoading(true);
    try {
      await saveMedicalRecord(documentText);
      setAlertInfos({
        type: "success",
        message: "Prontuário salvo com sucesso",
      });
    } catch (error) {
      setAlertInfos({
        type: "error",
        message: "Erro ao salvar prontuário",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (documentText !== "") {
      const localInfos =
        JSON.parse(sessionStorage.getItem("attendanceInfos")) || {};
      localInfos.documentText = documentText;
      sessionStorage.setItem("attendanceInfos", JSON.stringify(localInfos));
    }
  }, [documentText]);

  useEffect(() => {
    if (sessionStorage.getItem("attendanceInfos")) {
      const localInfos = JSON.parse(sessionStorage.getItem("attendanceInfos"));
      setDocumentText(localInfos.documentText);
    } else {
      setDocumentText(appointmentInfos?.report || "");
    }
  }, []);

  useEffect(() => {
    if (alertInfos.message !== "") {
      setTimeout(() => {
        setAlertInfos({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertInfos]);

  useEffect(() => {
    if (alertModalInfos.message !== "") {
      setTimeout(() => {
        setAlertModalInfos({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertModalInfos]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          position: "fixed",
          right: `${toggleWindow ? window.innerWidth / 3 : 0}px`,
          backgroundColor: "#FF785B70",
          width: "50px",
          bottom: `${window.innerHeight / 2 - 100}px`,
          borderRadius: "10px 0px 0px 10px",
          zIndex: 20000,
          cursor: "pointer",
        }}
        onClick={() => handleDrawer()}
      >
        <Box sx={{ position: "relative", height: "200px" }}>
          <Typography
            sx={{
              transform: "rotate(-90deg)",
              position: "absolute",
              transformOrigin: "center left",
              width: "250px",
              top: 180,
              left: 20,
              fontWeight: "bold",
              fontSize: "14px",
              zIndex: 30000,
            }}
            variant="caption"
          >
            Prontuário Eletrônico
          </Typography>
        </Box>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      </Box>

      <Drawer anchor="right" open={toggleWindow} onClose={handleDrawer}>
        <Box
          style={{
            width: `${window.innerWidth / 3}px`,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "10px",
            }}
          >
            Prontuário Eletrônico
          </Typography>

          {alertInfos.message !== "" && (
            <Alert
              severity={alertInfos.type}
              sx={{ marginX: "20px", marginY: "10px" }}
            >
              {alertInfos.message}
            </Alert>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => submitMedicalRecord()}
              sx={{ backgroundColor: "green" }}
            >
              <Typography
                variant="body2"
                fontWeight={700}
                sx={{ color: "#ffffff" }}
              >
                Salvar Prontuário
              </Typography>
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              padding: "10px",
            }}
          >
            <Typography
              variant="body2"
              fontWeight={500}
              color="red"
              textAlign="center"
            >
              Salve o prontuário sempre que fizer alterações.
            </Typography>
          </Box>
          <ReactQuill
            id="medicalRecordInput"
            theme="snow"
            modules={modules}
            formats={formats}
            placeholder="Insira o texto aqui..."
            value={documentText}
            onChange={(content) => setDocumentText(content)}
            style={{ height: `${window.innerHeight - 200}px`, padding: "10px" }}
          />
        </Box>
      </Drawer>
    </>
  );
}

export default EletronicMedicalRecord;
